window.addEventListener('load', async function () {
  const [{ default: Mounter }, { default: BulkActionsTray }] =
    await Promise.all([
      import(
        /* webpackChunkName: 'mounter_bulk_actions_tray' */ 'shared/utils/mounter'
      ),
      import(
        /* webpackChunkName: "bulk_actions_tray_component" */ 'bulk_actions/tray'
      ),
    ]);
  new Mounter({ BulkActionsTray }).mountAll();
});
