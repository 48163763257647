// Based on https://github.com/reactjs/react-rails/blob/83b6175460b6fd19d667854ebea4777d8c73705a/react_ujs/index.js

import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line max-len
// @ts-expect-error TODO: We need to @types/react and @types/react-dom to 18.x. This is hard to do currently since it contains breaking changes, requires updates in other dependent libraries.
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line max-len
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/utils/flatten_registry'. 'app/webpack/javascripts/shared/utils/flatten_registry.js' implicitly has an 'any' type.
import { flattenRegistry } from 'shared/utils/flatten_registry';
import timeInDevelopment from 'shared/utils/time_in_development';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager, ThemeProvider, WebTarget } from 'styled-components';
import documentReady from 'document-ready';
import { createTheme } from '@mui/material/styles';
import getTheme from '@grnhse/seedling/lib/birch/themes';

const CLASS_NAME_ATTR = 'data-react-class';
const PROPS_ATTR = 'data-react-props';
const DISABLE_CONCURRENT_MODE_ATTR = 'disable-concurrent-mode';

let mountedComponentsCount = 0;

type Component = any;

type ComponentRegistry = {
  [key: string]: Component;
};

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: WebTarget) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

class Mounter {
  static mountWhenReady(componentRegistry: ComponentRegistry) {
    documentReady(() => {
      new Mounter(componentRegistry).mountAll();
    });
  }

  componentRegistry: ComponentRegistry;
  // @ts-expect-error - TS2564 - Property '_flattenedRegistry' has no initializer and is not definitely assigned in the constructor.
  _flattenedRegistry: {
    [key: string]: Component;
  };

  constructor(componentRegistry: ComponentRegistry) {
    this.componentRegistry = componentRegistry;
  }

  findDomNodes = () => {
    return this.reactNodes().filter((reactNode) => {
      const componentName = reactNode.getAttribute(CLASS_NAME_ATTR);
      return this.registeredComponentNames().includes(componentName);
    });
  };

  reactNodes(selector = '') {
    // It's possible in certain browsers for nodes to not have the iterable methods, so coerce into an Array
    return Array.prototype.slice.call(
      document.querySelectorAll(`${selector} [${CLASS_NAME_ATTR}]`)
    );
  }

  registeredComponentNames = (): Array<string> => {
    return Object.keys(this.flattenedRegistry());
  };

  getComponentConstructor = (className: string) => {
    return this.flattenedRegistry()[className];
  };

  flattenedRegistry = (): {
    [key: string]: Component;
  } => {
    this._flattenedRegistry =
      this._flattenedRegistry || flattenRegistry(this.componentRegistry);
    return this._flattenedRegistry;
  };

  mount = (node: HTMLElement) => {
    mountedComponentsCount += 1;

    const className = node.getAttribute(CLASS_NAME_ATTR);

    if (!className) {
      throw `Unable to find className`;
    }

    timeInDevelopment(
      `Component mount #${mountedComponentsCount}: ${className}`,
      () => {
        const constructor = this.getComponentConstructor(className);
        const propsJson = node.getAttribute(PROPS_ATTR);
        const disableConcurrentMode = !!node.getAttribute(
          DISABLE_CONCURRENT_MODE_ATTR
        );
        const props = propsJson && JSON.parse(propsJson);

        const seedlingTheme = getTheme('birch');

        const element = React.createElement(constructor, props);
        const materialUITheme = createTheme();
        const wrappedElement = (
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <ThemeProvider theme={{ ...materialUITheme, ...seedlingTheme }}>
              {element}
            </ThemeProvider>
          </StyleSheetManager>
        );

        // we must attach to the window in case we need to unmount it later
        if (!disableConcurrentMode) {
          window.reactRootMap = window.reactRootMap || {};
          window.reactRootMap[className] = createRoot(node);
          window.reactRootMap[className].render(wrappedElement);
        } else {
          ReactDOM.render(wrappedElement, node);
        }
      }
    );
  };

  mountAll = () => this.findDomNodes().forEach(this.mount);
}

export default Mounter;
