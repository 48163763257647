import React from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

const INFORM_COOKIES_COOKIE = 'inform_cookies';

// Either 'greenhouse.io' on production or 'greenhouse.dev' on dev environments
const GREENHOUSE_DOMAIN = window.location.hostname
  .split('.')
  .slice(-2)
  .join('.');

const InformCookiesPopup = () => {
  const [cookies, setCookie] = useCookies([INFORM_COOKIES_COOKIE]);
  const showPopup = cookies[INFORM_COOKIES_COOKIE] !== 'false';

  // Only set by system specs as a more performant way to handle the pop up.
  if (localStorage.getItem('inform_cookies') === 'false') {
    return null;
  }

  const closePopup = () => {
    const expiry = new Date(
      new Date().setFullYear(new Date().getFullYear() + 5)
    );

    setCookie(INFORM_COOKIES_COOKIE, 'false', {
      path: '/',
      domain: GREENHOUSE_DOMAIN,
      expires: expiry,
    });
  };

  return (
    showPopup && (
      <OuterContainer data-provides="inform-cookies-popup">
        <InnerContainer>
          <InformHeader>This site uses cookies</InformHeader>
          <div>
            By continuing to use this site, you are agreeing to our use of
            cookies.{' '}
            <PrivacyPolicyLink
              href="https://www.greenhouse.io/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              See Privacy Policy
            </PrivacyPolicyLink>
          </div>

          <AcceptButton onClick={closePopup}>Ok</AcceptButton>
        </InnerContainer>
      </OuterContainer>
    )
  );
};

const OuterContainer = styled.div`
  font-family: 'Untitled Sans', sans-serif;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 230px;
  height: 170px;
  z-index: 9999;
  background-color: #424242;
  border-radius: 8px;
  color: white;
  font-size: 13px;
  line-height: 22px;
`;

const InnerContainer = styled.div`
  padding: 15px 16px 20px 20px;
`;
const InformHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const PrivacyPolicyLink = styled.a`
  color: white;
  text-decoration: underline;
`;

const AcceptButton = styled.button`
  position: absolute;
  bottom: 20px;
  font-size: 14px;
  height: 30px;
  width: 40px;
`;

export default InformCookiesPopup;
