const withTimingInDevelopment = (label: string, action: () => void) => {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.time(label);
  }

  action();

  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.timeEnd(label);
  }
};

export default withTimingInDevelopment;
