import _ from 'underscore';
import * as ReactIs from 'react-is';

/*
A registry like:
{
  Dashboard: {
    Tasks: () => <div>Tasks</div>,
    Interviews: () => <div>Interviews</div>,
  }
}

Is flattened to :
{
  'Dashboard.Tasks': () => <div>Tasks</div>,
  'Dashboard.Interviews': () => <div>Interviews</div>,
}
*/
export const flattenRegistry = (registry) => {
  if (
    registry === undefined ||
    registry === null ||
    typeof registry !== 'object'
  ) {
    return {};
  }

  const mappings = recursiveMappings(registry);

  return merge(mappings);
};

function isReactComponent(object) {
  return ReactIs.isValidElementType(object);
}

const recursiveMappings = (registry, prefixes = []) => {
  if (isReactComponent(registry)) {
    const path = prefixes.join('.');
    return { [path]: registry };
  } else if (typeof registry === 'object') {
    const mappings = Object.entries(registry).map(([key, value]) => {
      return recursiveMappings(value, prefixes.concat([key]));
    });
    return _.flatten(mappings);
  } else {
    throw new Error(
      'You can only initialize the Mounter with an object of React components'
    );
  }
};
const merge = (objects) => {
  return objects.reduce(
    (accumulator, object) => Object.assign({}, accumulator, object),
    {}
  );
};
