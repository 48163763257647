window.addEventListener('load', async function () {
  const [{ default: Mounter }, { default: RecentlyViewedComponent }] =
    await Promise.all([
      import(
        /* webpackChunkName: 'mounter_recently_viewed' */ 'shared/utils/mounter'
      ),
      import(
        /* webpackChunkName: "recently_viewed_component" */ 'recently_viewed/index'
      ),
    ]);
  new Mounter({ RecentlyViewedComponent }).mountAll();
});
